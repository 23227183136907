import DpGridMixin from 'src/core/components/DpGrid/DpGridMixin.js'
import DpGridPageMixin from 'src/core/components/DpGrid/DpGridPageMixin.js'
import DpGridAcoes from 'src/core/components/DpGrid/DpGridAcoes.vue'
import DpGrid from 'src/core/components/DpGrid/DpGrid2.vue'
import DpGridStore from 'src/store/dp-grid/'
import vTopFilters from 'src/core/filters/vTopFilters'
import ClienteModel from 'src/model/usuario/ClienteModel'

export default {
  components: { DpGrid, DpGridAcoes },
  mixins: [DpGridMixin, DpGridPageMixin, vTopFilters],
  data () {
    return {
      modelPadrao: new ClienteModel()
    }
  },
  meta () {
    return {
      title: 'Lista de Clientes',
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  },
  filters: {
    celularAjustado (v) {
      if (v) {
        v = v.replace(/\D/g, '') // Remove tudo o que não é dígito
        v = v.replace(/^(\d{2})(\d)/g, '(0$1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
        v = v.replace(/(\d)(\d{4})$/, '$1-$2')
        v = v.replace(/D/g, '')
        v = v.replace(/^(d{2})(d)/g, '($1) $2')
        v = v.replace(/(d)(d{4})$/, '$1-$2')
        return v
      } else {
        return v
      }
    }
  },
  mounted () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  }
}
